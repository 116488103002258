<script setup>
/* eslint vue/no-v-html: off */

import { ButtonTypes } from '@@/components/Common/Button.vue';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useDefaultTrial } from '@@/composables/useDefaultTrial';
import { useLoginView } from '@@/composables/useLoginView';
import { useLoginStore } from '@@/stores/Login';

defineProps({
  cardClassNames: {
    type: String,
    default: null,
  },
  isFullHeight: {
    type: Boolean,
    default: false,
  },
});

const { $loading, $toast } = useNuxtApp();
const loginStore = useLoginStore();
const route = useRoute();

const { defaultTrial } = useDefaultTrial();
const {
  isChangeEmailEnterEmailView,
  isLoginEnterEmailView,
  isForgotPasswordEnterEmailView,
  isRegisterEnterEmailView,
} = useLoginView();

const email = computed(() => loginStore.credentials.email);
const promoCode = computed(() => loginStore.promoCode);

const buttonText = computed(() => {
  if (isChangeEmailEnterEmailView.value
    || isRegisterEnterEmailView.value) {
    return 'Next';
  }

  if (isForgotPasswordEnterEmailView.value) {
    return 'Send Email';
  }

  return 'Log In';
});

const leadText = computed(() => {
  if (isForgotPasswordEnterEmailView.value) {
    return "Enter your email address and we'll send you a link to reset your password.";
  }

  if (isChangeEmailEnterEmailView.value) {
    return "We'll get started by sending you a quick email to confirm your address.";
  }

  if (isRegisterEnterEmailView.value) {
    if (promoCode.value && promoCode.value.is_usable) {
      return `${promoCodeDurationMessage.value} We'll get started by sending you a quick email to confirm your address.`;
    }

    if (promoCode.value && !promoCode.value.is_usable) {
      return `${promoCodeDurationMessage.value} You can still register and get a free trial on us. We'll get started by sending you a quick email to confirm your address.`;
    }

    const { duration_message, trial_messages } = defaultTrial.value;

    if (trial_messages?.length) {
      return trial_messages
        .map((trialMessage) => `<span class="tw-block tw-mb-2.5 last:tw-mb-0">${trialMessage}</span>`)
        .join('');
    }

    return `${duration_message} We'll get started by sending you a quick email to confirm your address.`;
  }

  if (promoCode.value && promoCode.value.is_usable) {
    return `Welcome back! Enter your OpenSnow email address to start your <strong class="tw-font-medium">${promoCode.value.code}</strong> Free Trial!`;
  }

  if (promoCode.value && !promoCode.value.is_usable) {
    return `${promoCodeDurationMessage.value} You can still log in. Enter your OpenSnow email address below to get started.`;
  }

  return 'Welcome back! Enter your OpenSnow email address below to get started.';
});

const promoCodeDurationMessage = computed(() => {
  if (!promoCode.value?.duration_message) {
    return '';
  }

  const { duration_message, code } = promoCode.value;

  return duration_message.replace(code, `<strong class="tw-font-medium">${code}</strong>`);
});

const handleFormSubmit = async () => {
  const handleError = (e) => {
    const { message } = parseOpenMountainApiError(e);

    $toast.open({
      dismissible: true,
      duration: 5000,
      message: message,
      type: 'error',
    });
  };

  const { return_to } = route.query;
  const payload = { return_to };

  $loading.start();

  try {
    if (isLoginEnterEmailView.value) {
      await loginStore.makeLoginRequest(payload);
    }
    else if (isForgotPasswordEnterEmailView.value) {
      await loginStore.makePasswordLinkRequest(payload);
    }
    else if (isChangeEmailEnterEmailView.value
      || isRegisterEnterEmailView.value) {
      let link_sent;

      if (isChangeEmailEnterEmailView.value) {
        link_sent = await loginStore.makeSettingsEmailLinkRequest();
      }
      else if (isRegisterEnterEmailView.value) {
        link_sent = await loginStore.makeRegisterLinkRequest(payload);
      }

      if (!link_sent) {
        throw new Error('Unable to send email!');
      }
    }
  }
  catch (e) {
    handleError(e);
  }

  $loading.finish();
};

const handleUpdateEmail = (value) => loginStore.setCredentials({ email: value });
</script>

<template>
  <Card
    :card-class-names="cardClassNames"
    :has-body-padding-responsive="true"
    :is-full-height="isFullHeight"
  >
    <template
      #body
    >
      <p
        class="tw-mb-2.5"
        v-html="leadText"
      />
      <Form
        v-slot="{ meta }"
        @submit="handleFormSubmit"
      >
        <Input
          label="Email Address"
          :can-trim-value="true"
          :model-value="email"
          :autofocus="true"
          :full-width="true"
          name="email"
          type="text"
          :required="true"
          rules="email"
          @update:model-value="handleUpdateEmail"
        />
        <Button
          class="tw-w-full tw-mt-4"
          :disabled="!meta.valid || !email"
          :display-block="true"
          :type="ButtonTypes.primary"
        >
          {{ buttonText }}
        </Button>
      </Form>
      <p
        v-if="isRegisterEnterEmailView"
        class="tw-mt-4"
      >
        By signing up you agree to our
        <NuxtLink
          class="link-color-brand"
          href="https://support.opensnow.com/legal/terms"
          target="_blank"
        >
          Terms of Use
        </NuxtLink>
        and
        <NuxtLink
          class="link-color-brand"
          href="https://support.opensnow.com/legal/privacy"
          target="_blank"
        >
          Privacy Policy
        </NuxtLink>.
      </p>
    </template>
  </Card>
</template>
